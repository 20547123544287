<template>
  <div class="q-pa-md">
      <VerticalStepper />
  </div>
</template>

<script>
import { ref } from "vue";
import VerticalStepper from "./vertical-stepper.vue";

export default {
  components: {
    VerticalStepper,
  },
  setup() {
    return {
      drawerLeft: ref(false),
      drawerRight: ref(true),
    };
  },
};
</script>
