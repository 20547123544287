<template>
  <runai-select-card :value="isSelected" @clicked="$emit('card-clicked')">
    <div class="k8s-dist workspace-card-content">
      <div class="dist-logo">
        <img :src="getImgUrl" style="width: 42px;">
      </div>
      <div
        class="k8s-dist-title q-mb-md"
        :class="{ 'text-primary': isSelected }"
      >
        <div class="col text-subtitle1 text-weight-medium ellipsis">
          {{ data.title }}
        </div>
      </div>
      <div class="k8s-dist-details">
        {{ data.description }}
      </div>
    </div>
  </runai-select-card>
</template>

<script>
import { defineComponent } from "vue";
import RunaiSelectCard from "@/components/runai-select-card";

export default defineComponent({
  components: {
    RunaiSelectCard,
  },
  emits: ["card-clicked"],
  props: {
    data: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    getImgUrl() {
      var images = require.context("../assets/svg", false, /\.svg$/);
      return images("./" + this.data.icon + ".svg");
    },
  },
});
</script>
