import "./styles/quasar.sass";
import "@quasar/extras/material-icons/material-icons.css";
import Notify from 'quasar/src/plugins/Notify.js';import Dark from 'quasar/src/plugins/Dark.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Notify,
    Dark
  },
};
