<template>
  <MainLayout />
</template>

<script>
import MainLayout from "./components/main-layout.vue";
export default {
  components: {
    MainLayout
  },
  setup() {
    return {
    };
  },
};
</script>