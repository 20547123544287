<template>
  <div class="q-pa-md">
    <q-stepper v-model="step" vertical color="primary" animated header-nav="true" >
      <generalSettings
        @environment_name="this.environmentName = $event"
        :environment_name="this.environmentName"
        @team_name="this.teamName = $event"
        :team_name="this.teamName"
        @update_step="this.step = $event"
        :update_step="this.step"
      />

      <kubernetesSettings
        @kubernetes_version="kubernetesVersionUpdater"
        :kubernetes_version="this.kubernetesVersion"
        @kubernetes_region="this.kubernetesRegion = $event"
        :kubernetes_region="this.kubernetesRegion"
        @worker_node_count="this.workerNodeCount = $event"
        :worker_node_count="this.workerNodeCount"
        @kubernetes_flavour="this.kubernetesFlavour = $event"
        :kubernetes_flavour="this.kubernetesFlavour"
        @update_step="this.step = $event"
        :update_step="this.step"
      />

      <runaiSettings
        :kubernetes_flavour="this.kubernetesFlavour"
        @installation_type="this.installationType = $event"
        :installation_type="this.installationType"
        @control_plane_version="this.controlPlaneVersion = $event"
        :control_plane_version="this.controlPlaneVersion"
        @control_plane_environment="this.controlPlaneEnvironment = $event"
        :control_plane_environment="this.controlPlaneEnvironment"
        @cluster_environment="this.clusterEnvironment = $event"
        :cluster_environment="this.clusterEnvironment"
        @air_gap_version="this.airGapVersion = $event"
        :air_gap_version="this.airGapVersion"
        @air_gap_environment="this.airGapEnvironment = $event"
        :air_gap_environment="this.airGapEnvironment"
        @engine_version="this.engineVersion = $event"
        :engine_version="this.engineVersion"
        @engine_environment="this.engineEnvironment = $event"
        :engine_environment="this.engineEnvironment"
        @cluster_version="this.clusterVersion = $event"
        :cluster_version="this.clusterVersion"
        @enable_alpha_flags="this.enableAlphaFlags = $event"
        :enable_alpha_flags="this.enableAlphaFlags"
        @enable_sso="this.enableSSO = $event"
        :enable_sso="this.enableSSO"
        @enable_multi-tenancy="this.enableMultiTenancy = $event"
        :enable_multi-tenancy="this.enableMultiTenancy"
        @is_csp_env="this.isCspEnv = $event"
        :is_csp_env="this.isCspEnv"
        @subdomain_support_enabled="this.subdomainSupportEnabled = $event"
        :subdomain_support_enabled="this.subdomainSupportEnabled"
        @enable_customTenant="this.customTenant.enabled = $event"
        :enable_customTenant="this.customTenant.enabled"
        @updateCustomTenant="this.customTenant = $event"
        :customTenant="this.customTenant"
        @update_step="this.step = $event"
        :update_step="this.step"
      />

      <gpuSettings
        @gpu_installation_type="this.gpuInstallationType = $event"
        :gpu_installation_type="this.gpuInstallationType"
        @fake_gpu_node_count="this.fakeGPUNodesCount = $event"
        @fake_gpu_count="this.fakeGPUCount = $event"
        :fake_gpu_count="this.fakeGPUCount"
        @fake_gpu_memory="this.fakeGPUMemory = $event"
        :fake_gpu_memory="this.fakeGPUMemory"
        @fake_gpu_product="this.fakeGPUProduct = $event"
        :fake_gpu_product="this.fakeGPUProduct"
        @real_gpu_node_count="this.realGPUNodeCount = $event"
        :real_gpu_node_count="this.realGPUNodeCount"
        @real_gpu_product="this.realGPUPoduct = $event"
        :real_gpu_product="this.realGPUPoduct"
        @update_step="this.step = $event"
        :update_step="this.step"
        :kubernetes_flavour="this.kubernetesFlavour"
        :additionalGpus="this.additionalGPUs"
        @updateAdditionalGpus="this.additionalGPUs = $event"
      />

      <thirsPartyTools
        :kubernetes_version="this.kubernetesVersion"
        :kubernetes_flavour="this.kubernetesFlavour"
        :installation_type="this.installationType"
        @install_training_operator="this.installTraningOperator = $event"
        :install_training_operator="this.installTraningOperator"
        @enable_kwok="this.enableKwok = $event"
        :enable_kwok="this.enableKwok"
        @kwok_ratio="this.kwokRatio = $event"
        :kwok_ratio="this.kwokRatio"
        @install_kubeflow="updateKubeflow"
        :install_kubeflow="this.installKubeflow"
        @install_mpi_operator="this.installMPIOperator = $event"
        :install_mpi_operator="this.installMPIOperator"
        @install_nfs="this.installNFS = $event"
        :install_nfs="this.installNFS"
        @install_knative="this.installKnative = $event"
        :install_knative="this.installKnative"
        @install_harbor="this.installHarbor = $event"
        :install_harbor="this.installHarbor"
        @update_step="this.step = $event"
        :update_step="this.step"
        @cluster_installer_enabled="this.clusterInstallerEnabled = $event"
        :cluster_installer_enabled="this.clusterInstallerEnabled"
        @install_fluent_bit="this.installFluentBit = $event"
        :install_fluent_bit="this.installFluentBit"
        @yaml_dialog_state="createEnvironment"
        @enable_ingress_controller="this.ingressController.enabled = $event"
        :enable_ingress_controller="this.ingressController.enabled"
        @updateIngressController="this.ingressController = $event"
        :ingressController="this.ingressController"
      />

    </q-stepper>
  </div>
</template>

<script>
import generalSettings from "./general-settings.vue";
import kubernetesSettings from "./kubernetes-settings.vue";
import runaiSettings from "./runai-settings.vue";
import gpuSettings from "./gpu-settings.vue";
import thirsPartyTools from "./thirdparty-tools.vue";
import jsYaml from "js-yaml";
// import environmentSummary from "./environment-summary.vue";
import { ref } from "vue";
import { copyToClipboard } from "quasar";
import axios from "axios";
export default {
  components: {
    kubernetesSettings,
    runaiSettings,
    generalSettings,
    gpuSettings,
    thirsPartyTools,
    // environmentSummary,
  },
  methods: {
    onCopy() {
      copyToClipboard(this.yaml)
        .then(() => {
          this.$q.notify({ type: "positive", message: "success!" });
        })
        .catch(() => {
          this.$q.notify({
            type: "negative",
            message: "fail to copy",
          });
        });
      this.$emit("yaml_dialog_state", false);
    },
    controlPlaneVersionDisplay() {
      if (this.controlPlaneVersion !== "latest") {
        return `- name: runaiControlPlane.version
          value: '${this.controlPlaneVersion}'`
      }
      return ''
    },
    engineVersionDisplay() {
      if (this.engineVersion !== "latest") {
        return `- name: runaiEngine.version
          value: '${this.engineVersion}'`
      }
      return ''
    },
    clusterVersionDisplay() {
      if (this.clusterVersion !== "latest") {
        return `- name: runaiCluster.version
          value: '${this.clusterVersion}'`
      }
      return ''
    },
    airGapVersionDisplay() {
      if (this.airGapVersion !== "") {
        return `- name: airgapped.version
          value: '${this.airGapVersion}'`
      }
      return ''
    },
    customTenantDisplay() {
      if (this.customTenant.enabled) {
        return `- name: customTenant.enabled
          value: '${this.customTenant.enabled}'
        - name: customTenant.url
          value: '${this.customTenant.url}'
        - name: customTenant.clientID
          value: '${this.customTenant.clientID}'
        - name: customTenant.clientPassword
          value: '${this.customTenant.clientPassword}'
        - name: customTenant.name
          value: '${this.customTenant.name}'
        - name: customTenant.username
          value: '${this.customTenant.username}'
        - name: customTenant.clientSecret
          value: '${this.customTenant.clientSecret}'`
      }
      return ''
    },
    ingressControllerDisplay() {
      return `- name: ingressController.enabled
          value: '${this.ingressController.enabled}'
        - name: ingressController.type
          value: '${this.ingressController.type}'`
    },
    clusterInstallerEnabledDisplay() {
      if (this.clusterInstallerEnabled) {
        return `- name: runaiCluster.clusterInstaller.enabled
          value: '${this.clusterInstallerEnabled}'`
      }
      return ''
    },
    kwokEnabledDisplay() {
      if (this.enableKwok) {
        return `- name: kwok.enabled
          value: '${this.enableKwok}'
        - name: kwok.ratio
          value: '${this.kwokRatio}'`
      }
      return ''
    },
    installFluentBitDisplay() {
      if (this.installFluentBit) {
        return `- name: fluentBit.enabled
          value: '${this.installFluentBit}'`
      }
      return ''
    },
    subdomainSupportEnabledDisplay() {
      return `- name: runaiCluster.subdomainSupport
          value: '${this.subdomainSupportEnabled}'`
    },
    updateKubeflow(kubeflowValue) {
      this.installKubeflow = kubeflowValue;
      if(this.installKubeflow){
        this.installTraningOperator = false;
      }
    },
    createEnvironment(yamlDialogState) {
      console.log(yamlObj);
      if(yamlDialogState){
        var yamlObj = jsYaml.load(this.yaml);
        window.top.location.href = `https://argocd-infra-main-01.runailabs.com/applications?showFavorites=false&proj=&sync=&health=&namespace=&cluster=&labels=&new=${JSON.stringify(yamlObj)}`;
      }
    },
    kubernetesNodeCountDisplay() {
      if (this.workerNodeCount) {
        return `- name: kubernetes.nodeCount
          value: '${this.workerNodeCount}'`
      }
      return ''
    },
    kubernetesVersionUpdater(kubernetes_version){
      this.kubernetesVersion = kubernetes_version
      if (!kubernetes_version || +kubernetes_version?.slice(0,4) > 1.24) {
        this.installKubeflow = false
      }
    },
    async getEnvinAclickChart() {
      this.envInAclickChartVersion = await axios
        .get(
          `https://env-in-a-click-generator-ui.runailabs.com/api/env-in-a-click-version`
        )
        .then((response) => {
          return response.data;
        });
    },
  },
  data() {
    return {
      environmentName: null,
      teamName: null,
      kubernetesVersion: null,
      kubernetesRegion: "us-east-1",
      workerNodeCount: null,
      kubernetesFlavour: "kind",
      step: 1,
      clusterVersion: "latest",
      controlPlaneVersion: "latest",
      clusterEnvironment: "prod",
      engineVersion: "latest",
      engineEnvironment: "prod",
      controlPlaneEnvironment: "prod",
      airGapEnvironment: "prod",
      installationType: "Self-Hosted",
      fakeGPUNodesCount: 1,
      fakeGPUCount: 16,
      fakeGPUMemory: 12000,
      fakeGPUProduct: "Tesla-V100",
      additionalGPUs:[],
      realGPUNodeCount: 1,
      realGPUPoduct: "T4-1",
      gpuInstallationType: "Fake",
      installTraningOperator: false,
      subdomainSupportEnabled: false,
      clusterInstallerEnabled: false,
      enableAlphaFlags: false,
      enableMultiTenancy: false,
      enableKwok: false,
      kwokRatio: 20,
      installKubeflow: false,
      installMPIOperator: false,
      installHarbor: false,
      installNFS: false,
      installFluentBit: false,
      installKnative: false,
      enableSSO: false,
      yamlDialogState: ref(false),
      envInAclickChartVersion: this.getEnvinAclickChart(),
      customTenant: {
        enabled: false,
        url: null,
        name: null,
        clientID: null,
        clientSecret: null,
        username: null,
        clientPassword: null,
      },
      ingressController: {
        enabled: true,
        type: ["nginx"],
      },
      airgapped: {
        enabled: false,
        env: null,
        version: null
      },
      isCspEnv: false,
    };
  },
  computed: {
    additionalGPUsYaml() {
      if (!this.additionalGPUs.length) return "";
      return this.additionalGPUs.reduce((acc, gpu) => {
        acc += `              - gpuNodesCount: ${gpu.fakeGPUNodesCount} \n`
        acc += `                gpuCount: ${gpu.fakeGPUCount} \n`
        acc += `                gpuMemory: ${gpu.fakeGPUMemory} \n`
        acc += `                gpuProduct: ${gpu.fakeGPUProduct} \n`
        return acc
      }, "GPU:\n          fake:\n            additionalGPUs:\n")
    },
    yaml() {
      return `apiVersion: argoproj.io/v1alpha1
kind: Application
metadata:
  name: ${this.environmentName}
  labels:
    env-in-a-click/auto-delete-after: 1w
    env-in-a-click/managed: 'true'
    env-in-a-click/cloud-provider: ${this.kubernetesFlavour.toLowerCase()}
    env-in-a-click/installation-type: ${this.installationType.toLowerCase()}
    env-in-a-click/team-name: ${this.teamName}
    env-in-a-click/gpu-type: ${this.gpuInstallationType.toLowerCase()}
spec:
  project: ${this.teamName}
  source:
    repoURL: 'https://runai.jfrog.io/artifactory/env-in-a-click'
    targetRevision: ${this.envInAclickChartVersion['Version']}
    helm:
      values: |
        ${this.additionalGPUsYaml}
      parameters:
        - name: cloudProvider
          value: '${this.kubernetesFlavour.toLowerCase()}'
        - name: runaiEngine.enabled
          value: '${this.installationType.toLowerCase() === "engine"}'
        ${this.engineVersionDisplay()}
        - name: runaiEngine.env
          value: '${this.engineEnvironment ? this.engineEnvironment : "prod"}'       
        - name: runaiControlPlane.enabled
          value: '${this.installationType.toLowerCase() === "self-hosted" || this.installationType.toLowerCase() === "multi-tenancy" || this.installationType.toLowerCase() === "air-gap"}'
        ${this.controlPlaneVersionDisplay()}
        - name: runaiControlPlane.env
          value: '${
            this.controlPlaneEnvironment ? this.controlPlaneEnvironment : "prod"
          }'
        - name: runaiCluster.enabled
          value: '${this.installationType.toLowerCase() === "saas" || this.installationType.toLowerCase() === "self-hosted" || this.installationType.toLowerCase() === "air-gap"}'
        ${this.clusterVersionDisplay()}
        - name: runaiCluster.env
          value: '${this.clusterEnvironment ? this.clusterEnvironment : "prod"}'
        - name: GPU.enabled
          value: '${this.gpuInstallationType.toLowerCase() !== "none"}'
        - name: GPU.gpuInstallationType
          value: '${this.gpuInstallationType.toLowerCase()}'
        - name: GPU.fake.gpuCount
          value: '${this.fakeGPUCount}'
        - name: GPU.fake.gpuMemory
          value: '${this.fakeGPUMemory}'
        - name: GPU.fake.gpuProduct
          value: '${this.fakeGPUProduct}'
        - name: GPU.real.nodeCount
          value: '${this.realGPUNodeCount}'
        - name: GPU.real.gpuProduct
          value: '${this.realGPUPoduct}'
        - name: kubernetes.version
          value: '${this.kubernetesVersion ? this.kubernetesVersion : ""}'
        - name: region
          value: '${this.kubernetesRegion}'
        ${this.kubernetesNodeCountDisplay()}
        - name: knative.enabled
          value: '${this.installKnative}'
        - name: mpiOperator.enabled
          value: '${this.installMPIOperator}'
        - name: sso.enabled
          value: '${this.enableSSO}'
        - name: kubeflow.enabled
          value: '${this.installKubeflow}'
        - name: harbor.enabled
          value: '${this.installHarbor}'
        - name: nfs.enabled
          value: '${this.installNFS}'
        - name: trainingOperator.enabled
          value: '${this.installTraningOperator}'
        - name: runaiControlPlane.alphaFlags
          value: '${this.enableAlphaFlags}'
        - name: runaiControlPlane.multiTenancy
          value: '${this.installationType.toLowerCase() === "multi-tenancy"}'
        - name: runaiControlPlane.isCspEnv
          value: '${this.isCspEnv}'
        - name: teamName
          value: '${this.teamName}'
        ${this.customTenantDisplay()}
        ${this.ingressControllerDisplay()}
        ${this.kwokEnabledDisplay()}
        ${this.clusterInstallerEnabledDisplay()}
        ${this.installFluentBitDisplay()}
        ${this.subdomainSupportEnabledDisplay()}
        - name: airgapped.enabled
          value: '${this.installationType.toLowerCase() === "air-gap"}'
        ${this.airGapVersionDisplay()}
        - name: airgapped.env
          value: '${
            this.airGapEnvironment ? this.airGapEnvironment : "prod"
          }'
    chart: env-in-a-click-chart
  destination:
    server: 'https://kubernetes.default.svc'
    namespace: ${this.environmentName}
  syncPolicy:
    automated:
      prune: true
      selfHeal: true
    syncOptions:
      - CreateNamespace=true`;
    },
  },
};
</script>

<style lang="scss" scoped>
.yaml-preview {
  border: 1px solid $grey-4;
  border-radius: 3px;
  height: 100%;
}
</style>
