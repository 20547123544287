<template>
  <q-step
    :name="1"
    title="General settings"
    icon="settings"
    :done="this.update_step > 1"
  >
  
    <q-form ref="general-settings-form">
      <div class="row">
        <div>
          <q-input
          counter
          maxlength="30"
          v-model="environmentName"
          placeholder="Environment name"
          @update:model-value="$emit('environment_name', $event)"
          style="margin-bottom: 10px; width: 250px"
          :rules="[
            envRequiredRule,
            envNoCapsRule,
            envNoSpacesRule,
            envNoSpecialCharsRule,
            envNotStartWithNumberRule,
            envOnlyEnglishRule
          ]"
        />
        <q-tooltip :delay="1000">
          This is the name of the environment that will be created.<br>
          It must be lowercase, contain no spaces, and no special characters.
        </q-tooltip>
        </div>

        <div>
          <q-select
          label="Team name"
          v-model="teamName"
          :options="teamNameOptions"
          style="width: 250px; margin-left: 40px"
          @update:model-value="$emit('team_name', $event)"
          select-placeholder="Team name"
          :rules="[teamNameRequiredRule]"
        />
        <q-tooltip :delay="1000">
          This must be the name of your team.<br>
          If your team is not listed, please contact the DevOps team.
        </q-tooltip>
        </div>

      </div>
      <q-stepper-navigation>
        <q-btn @click="onClick" color="primary" label="Continue" />
        <q-btn @click="onSkip" color="primary" class="q-ml-sm" label="Skip to end" />
      </q-stepper-navigation>
    </q-form>
  </q-step>
</template>

<script>
export default {
  components: {},
  props: {
    update_step: {
      type: Number,
      required: true,
    },
    environment_name: {
      type: String,
      required: true,
      
    },
    team_name: {
      type: String,
      required: true,
    },
  },
  methods: {
    async onSkip() {
      if (await this.$refs["general-settings-form"].validate()) {
        this.$emit("update_step", 5);
      }
    },
    async onClick() {
      if (await this.$refs["general-settings-form"].validate()) {
        this.$emit("update_step", 2);
      }
    },
    envNotStartWithNumberRule(val) {
      if (val.match(/^\d/)) {
        return "Environment name must not start with a number";
      }
      return true;
    },
    envRequiredRule(val) {
      if (!val) {
        return "Environment name is required";
      }
      return true;
    },
    envNoCapsRule(val) {
      if (val !== val.toLowerCase()) {
        return "Environment name must be lowercase";
      }
      return true;
    },
    envNoSpacesRule(val) {
      if (val.match(/\s+/g)) {
        return "Environment name must not contain spaces";
      }
      return true;
    },
    envNoSpecialCharsRule(val) {
      if (val.match(/[!@#$%^&*(),.?":{}|<>]/g)) {
        return "Environment name must not contain special characters";
      }
      return true;
    },
    envOnlyEnglishRule(val) {
      if (val.match(/[^a-zA-Z0-9-]/g)) {
        return "Environment name must only contain English characters";
      }
      return true;
    },
    teamNameRequiredRule(val) {
      if (!val) {
        return "Team name is required";
      }
      return true;
    },
  },
  data() {
    return {
      step: this.update_step,
      environmentName: this.environment_name,
      teamName: this.team_name,
      teamNameOptions: [
        "devops",
        "qa",
        "scheduler",
        "griffin",
        "suricata",
        "pre-sales",
        "viper",
        "pheonix",
        "sphinx",
        "lemur",
        "fox",
        "customer-success",
        "core",
        "product",
        "engineers-leads",
      ],
    };
  },
};
</script>
