<template>
  <label class="runai-select-card" :for="cmpId">
    <q-card
      class="card-content"
      :class="{ 'is-checked': value, 'is-disabled': disabled }"
      style="width: 200px;height: 200px;margin: 5px;"
    >
      <slot />
      <input
        type="checkbox"
        :id="cmpId"
        :value="value"
        @click="!disabled && $emit('clicked')"
      />
      <q-icon class="checkmark" color="white" name="checkmark" />
    </q-card>
  </label>
</template>

<script>
import { defineComponent } from "vue";
import { uid } from "quasar";

export default defineComponent({
  events: ["clicked"],
  props: {
    value: {
      type: Boolean,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      cmpId: uid(),
    };
  },
});
</script>

<style lang="scss" scoped>
.runai-select-card {
  $checkmark-size: 21px;
  $checkmark-position: 3px;
  .card-content {
    display: inline-block;
    position: relative;
    cursor: pointer;
    border: 3px solid transparent;
    padding: 15px;
    min-width: 50px;
    min-height: 50px;
    transition: all 0.3s;

    &:hover:not(&.is-disabled),
    &.is-checked {
      border: 3px solid #0654fe;
    }

    &.is-disabled {
      cursor: default;
    }

    &.is-checked {
      .checkmark {
        opacity: 1;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: -3px;
      right: -3px;
      height: 21px;
      width: 21px;
      background-color: #0654fe;
      opacity: 0;
      transition: all 0.3s;
      border-radius: 5px;
      line-height: 20px;
      font-weight: bold;
      font-size: 18px;
    }
  }
}
</style>
